<template>
    <div class="evalute-success">
        <div class="evalute-head">
            <div class="head-img">
                <img src="@/assets/img/evalute-success-icon.png" >
            </div>
            <p class="head-txt">{{$t("evaluate.evaluatsuccess")}}</p>
            <div class="head-btn">
                <p class="btn" @click="goPath(1)">
                    {{$t("evaluate.gohome")}}
                </p>
                <p class="btn" @click="goPath(2)">
                    {{$t("evaluate.seeEvaluat")}}
                </p>
            </div>
        </div>
        <div class="other-evaluate" v-if="otherEvalutate.length > 0">
            <p class="title"> <i class="icon"></i> <span>{{$t("evaluate.ToWritingEvaluat")}}</span></p>
            <div v-for="(item ,index) in otherEvalutate" :key="index" class="evalute-goods-item">
                <div class="item-box">
                    <img :src="item.goodsPryImgUrl" alt="">
                    <div class="item-details">
                        <p class="title">{{item.goodsName}}</p>
                        <div class="item-price">
                            <span class="price">¥{{item.goodsSellPrice}}</span>
                            <span class="evaluteBtn" @click="writeEvaluate(item)">{{$t("evaluate.WritingEvaluat")}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { unevalute_GoodsQuery } from "@/api/user";
export default {
    data(){
        return{
            otherEvalutate:[]
        }
    },
    created(){
        this.getOtherEvaluate();
    },
    methods:{
        async getOtherEvaluate(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {
                pageNum:1,
                pageSize:1
            }
            let result = await unevalute_GoodsQuery(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.otherEvalutate = result.data.data.data;
            }
        },
        writeEvaluate(item){
            this.$router.push({
                path:"/my/editEvalute",
                query:{
                    orderId:item.orderId,
                    goodsId:item.goodsId,
                    goodsUrl:item.goodsPryImgUrl
                }
            })
        },
        goPath(type){
            if(type == 1){
                this.$router.push({
                    path:"/shop/home"
                })
            }else{
                this.$router.go(-2);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.evalute-success{
    height: 100%;
    .evalute-head{
        height: 8.1rem;
        background: url("~@/assets/img/evalute-succes-back.png") no-repeat;
        background-size: 100% 100%;
        .head-img{
            padding-top: .8rem;
            img{
                width: auto;
                display: block;
                margin: 0 auto;
                height: 2.8rem;
            }
        }
        .head-txt{
            font-size: 0.72rem;
            text-align: center;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1rem;
            margin: 0.4rem auto;
        }
        .head-btn{
            width: 8.6rem;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            .btn{
                width: 3.68rem;
                height: 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.48rem;
                font-weight: 400;
                border-radius: 0.6rem;
                color: #FFFFFF;
                border: 0.02rem solid #FFFFFF;
            }
        }
    }
    .other-evaluate{
        margin-top: -0.72rem;
        padding: 0.72rem 0.6rem 0 0.6rem;
        background: #FFFFFF;
        border-radius: 0.72rem 0.72rem 0 0;
        &>.title{
            font-size: 0.6rem;
            font-weight: 600;
            color: #303133;
            display: flex;
            line-height: 0.84rem;
            position: relative;
            align-items: center;
            width: max-content;
            &::after{
                content: " ";
                position: absolute;
                width: 100%;
                height: 0.2rem;
                background: #FFD3D3;
                bottom: 0;
                z-index: 1;
            }
            span{
                z-index: 9;
            }
            .icon{
                position: relative;
                z-index: 9;
                display: block;
                width: 0.58rem;
                height: 0.58rem;
                margin-right: 0.1rem;
                float: left;
                background: url("~@/assets/img/write-one-icon.png") no-repeat ;
                background-size: 100% 100%;
            }
        }
        .evalute-goods-item{
            padding: 0.8rem 0.6rem;
            border-bottom: 0.04rem solid  #F5F6FA;
            &:last-child{
                border: 0;
            }
            .item-box{
                display: flex;
                // background: #F2F6FC;
                align-items: center;
                img{
                    height: 2.8rem;
                    flex: auto 0 0;
                    margin-right: 0.6rem;
                    width: 2.8rem;
                }
                .item-details{
                    flex: 1;
                    overflow: hidden;
                    padding-right: 0.5rem;
                    .title{
                        font-size: 0.6rem;
                        font-weight: 400;
                        color: #303133;
                        line-height: 0.84rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .item-price{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 0.3rem;
                        .price{
                            font-size: 0.56rem;
                            font-family: DINAlternate-Bold, DINAlternate;
                            font-weight: bold;
                            color: #303133;
                            line-height: 0.64rem
                        }
                        .evaluteBtn{
                            font-size: 0.48rem;
                            font-weight: 400;
                            color: #FF5252;
                            line-height: 0.66rem;
                            padding: 0.16rem 0.64rem;
                            border: 1px solid #FF5252;
                            border-radius: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
